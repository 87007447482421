<template>
    <div class="row justify-center">

        <div class="col-12 col-md-5 q-pa-md">
            <q-select outlined
                v-model="tipo_simplo"
                :options="options_tipo_simplo"
                label="Tipo di simplo"
                ref="tipo_simplo"
            />
        </div>

        <div class="col-12 col-md-5 q-pa-md">
            <q-input outlined v-model="nominativo_cliente" label="Nominativo Cliente" ref="nominativo_cliente" />
        </div>

    <!--    <div class="col-12 col-md-12" align="center">
            <q-btn color="primary" label="Cerca" />
            <hr>
        </div> //-->

        <div class="col-12 col-md-12">
            <q-table
                title="Elenco dei Simpli da caricare"
                :data="elenco_filtrato"
                :columns="colonne"
                :dense="$q.screen.lt.md"
                row-key="prodotto_id"
                :visible-columns="visibleColumns"
                wrap-cells
            >
            <!--        <template v-slot:top-right>
                        <div class="panel-cerca">
                            <q-input outlined dense debounce="300" v-model="filter" placeholder="Cerca" style="min-width: 350px;">
                                <template v-slot:append>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
                        </div>
                    </template> //-->

                <template v-slot:top-right>
                    <q-btn
                        color="primary"
                        icon-right="archive"
                        label="ESPORTA IN CSV"
                        no-caps
                        @click="esportaTabella"
                    />
                </template>

                <template v-slot:header="props">
                    <q-tr :props="props">

                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                            {{ col.label }}
                        </q-th>

                        <q-th>
                            Opzioni
                        </q-th>

                    </q-tr>

                </template>

                <template v-slot:body="props">
                    <q-tr :props="props">

                        <q-td
                            v-for="row in props.cols"
                            :key="row.name"
                            :props="props"
                        >
                            {{ row.value }}
                        </q-td>

                        <q-td>
                            <q-btn
                                v-if="props.cols[2].value === 'NON_CARICATO'"
                                style="width: 200px;"
                                color="green"
                                glossy
                                label="CARICA SIMPLO"
                                @click.native="onUploadSimpli(props.row)"
                            />

                            <q-btn
                                v-if="props.cols[2].value === 'CARICATO_SCARTATO'"
                                style="width: 200px;"
                                color="deep-orange"
                                glossy
                                label="CARICA SIMPLO"
                                @click.native="onUploadSimpli(props.row)"
                            />
                        </q-td>

                    </q-tr>

                </template>

            </q-table>
            <br><br><br><br><br>
            <br><br><br><br><br>
        </div>

    </div>

</template>

<script>
    //import axios from 'axios';
    //import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy";
    //import commonLib from "@/libs/commonLib";
    //import {mapFields} from "vuex-map-fields"
    import { mapActions } from "vuex";
    import { exportFile } from 'quasar';

    function wrapCsvValue (val, formatFn) {
        let formatted = formatFn !== void 0
            ? formatFn(val)
            : val

        formatted = formatted === void 0 || formatted === null
            ? ''
            : String(formatted)

        formatted = formatted.split('"').join('""')
        /**
         * Excel accepts \n and \r in strings, but some other CSV parsers do not
         * Uncomment the next two lines to escape new lines
         */
        // .split('\n').join('\\n')
        // .split('\r').join('\\r')

        return `"${formatted}"`
    }

    export default {
        name: "SimpliDaCaricare",
        data() {
            return {
                filter: '',
                righe: [],
                colonne: [],
                options_tipo_simplo: [
                    'Tutti', 'Solo Simpli da caricare', 'Solo Simpli scartati'
                ],
                tipo_simplo: "Tutti",
                nominativo_cliente: "",
                visibleColumns: [ 'nominativo', 'unita_operativa', 'stato', 'prodotto_id', 'compagnia', 'created_at', 'decorrenza', 'descrizione' ],
            }
        },
        components: {
            //QQButton
        },
        computed: {
            elenco_filtrato() {
                var elenco = this.righe;

                if (this.tipo_simplo == "Solo Simpli da caricare") {
                    elenco = this.righe.filter(e => {
                        return (e.stato === "NON_CARICATO");
                    })
                }

                if (this.tipo_simplo == "Solo Simpli scartati") {
                    elenco = this.righe.filter(e => {
                        return (e.stato === "CARICATO_SCARTATO");
                    })
                }

                if (this.nominativo_cliente !== "") {
                    elenco = elenco.filter(e => {
                        return (e.nominativo.includes(this.nominativo_cliente));
                    })
                }


                return elenco;
            }

        },
        methods: {
            ...mapActions({
                fetchElencoSimpliDaCaricare: "gestioneSimpli/fetchElencoSimpliDaCaricare",
                setDatiPraticaPerSimplo: "gestioneSimpli/setDatiPraticaPerSimplo"
            }),
            esportaTabella() {
                // naive encoding to csv format
                const content = [ this.colonne.map(col => wrapCsvValue(col.label)) ].concat(
                    this.righe.map(row => this.colonne.map(col => wrapCsvValue(
                        typeof col.field === 'function'
                            ? col.field(row)
                            : row[col.field === void 0 ? col.name : col.field],
                        col.format
                    )).join(','))
                ).join('\r\n')

                const status = exportFile(
                    'SimpliDaCaricare.csv',
                    content,
                    'text/csv'
                )

                if (status !== true) {
                    this.$q.notify({
                        message: 'Browser denied file download...',
                        color: 'negative',
                        icon: 'warning'
                    })
                }
            },
            onUploadSimpli(record) {
                console.log("record:",record);
                this.setDatiPraticaPerSimplo({
                    guidPratica: record.numero,
                    idpratica: record.idpratica,
                    idcontratto: record.idcontratto,
                    idquietanza: record.idquietanza,
                    prodotto: record.prodotto,
                    compagnia: record.compagnia,
                    decorrenza: record.decorrenza,
                    scadenza: record.scadenza
                });

                this.$router.push({name : "Simpli.UploadSimpli"});
            }
        },
        async mounted() {
            var dati     = await this.fetchElencoSimpliDaCaricare();
            this.righe   = dati.rows;
            this.colonne = dati.columns;
        }
    }
</script>
